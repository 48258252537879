import { useEffect, useState } from 'react';

export const useAnimation = (initialState: boolean, duration?: number) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isVisible && !initialState) {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, duration || 300);
    }
    setIsVisible(initialState);
  }, [isVisible, initialState, duration]);

  return [isVisible, showAnimation] as const;
};
