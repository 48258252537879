import { useImgContext } from '../context/ImgContext';
import { AlertIcon, CloseIcon } from 'assets/icons';
import {
  BodyTextM,
  ButtonText2,
  GRAY20,
  GRAY30,
  GRAY50,
  GRAY70,
} from 'global-styles';
import { MainButton } from 'global-styles/MainButton';
import styled, { css, keyframes } from 'styled-components';
import { useAnimation } from 'shared-utils';
import { appearAnim } from './Carousel/style';

const AppModal = ({ locale }: { locale: string }) => {
  const {
    state: { isOpenModal, errorMsg },
    dispatch,
  } = useImgContext();
  const [isVisible, showAnimation] = useAnimation(isOpenModal, 180);
  const handleCloseModal = () => dispatch({ type: 'closeModal' });

  if (!isVisible && !showAnimation) return null;

  return (
    <AppModalContainerBackground showAnimation={showAnimation}>
      <AppModalContainer>
        <div className="m-header">
          <div className="m-h-t">
            <AlertIcon />
            <ButtonText2>Alert</ButtonText2>
          </div>
          <button className="close-btn" onClick={handleCloseModal}>
            <CloseIcon color={GRAY50} />
          </button>
        </div>
        <div className="m-body">
          <div className="t-area">
            <BodyTextM>{errorMsg || 'Unknown Error has occured.'}</BodyTextM>
          </div>
          <div className="btn-area">
            <MainButton
              onClick={handleCloseModal}
              buttonType="default"
              text={locale === 'en' ? 'OK' : '확인'}
              className="ok-btn"
            />
          </div>
        </div>
      </AppModalContainer>
    </AppModalContainerBackground>
  );
};

export default AppModal;

interface ModalContainerType {
  showAnimation: boolean;
}

export const opacityDisappear = keyframes`
  0% {
    opacity: 1;
  } 100% {
    opacity: 0;
  }
`;

const AppModalContainerBackground = styled.div<ModalContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${appearAnim} 0.18s;
  ${({ showAnimation }) =>
    showAnimation &&
    css`
      animation: ${opacityDisappear} 0.18s;
    `};
`;

const AppModalContainer = styled.div`
  width: 328px;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 16px;
  color: #fff;

  & .m-header {
    position: relative;
    color: ${GRAY20};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    height: 48px;
  }
  & .m-header .close-btn {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }
  .m-h-t {
    display: flex;
    align-items: center;
  }
  .m-h-t svg {
    margin-right: 4px;
  }
  & .m-body {
    height: calc(100% - 48px);
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .t-area {
    text-align: center;
    min-width: 220px;
    color: ${GRAY30};
    white-space: pre-wrap;
    margin-bottom: 16px;
  }
  .ok-btn {
    /* border: 1px solid ${GRAY70}; */
    /* border-radius: 28px; */
    padding: 14px 0;
    width: 156px;
  }
`;
